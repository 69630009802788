.essays-page {
  height: 100vh;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  background-image: url('../../images/IMG_5030_16_9.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: hidden;
}

  .essays-header {
    display: flex;
    justify-content: center;
    flex: 1;
    font-size: inherit;
    font-family: inherit;
  }

  .essays-header h3 {
    width: fit-content;
    align-self: flex-end;
    background-color: var(--salmon);
    background-color: #42778cff;
    color: white;
  }

.essay-tagline {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end; 
    justify-content: flex-end;
    align-self: flex-end;
    flex: 1;
    font-size: inherit;
    font-family: inherit;
  }

  .essay-tagline p {
    width: fit-content;
    background-color: #f1ecd7;
  }

  .essay-links {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    word-wrap: break-word;
    font-size: inherit;
    font-family: 'Sofia Sans Condensed';
    font-weight: 400;
  }

  .essay-link {
    margin-bottom: .6em;
    width: fit-content;
    display: inline-block;
    white-space: normal;
    font-family: inherit;
    font-size: inherit;
  }

    .essay-link a {
      padding: .2em .4em .1em .4em;
      display: inline-block;
      background-color: #8498a2;
      color: white;
      font-family: inherit;
      text-decoration: none;
    }

    .essay-links a:hover {
    }

    .essay-links a:active {
    }

    .date {
      display: inline;
      color: #888;
    }