.booklist-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    overflow-x: hidden;
}

    .booklist-header h2 {
    }

    .full-book-list {}

    .book-section {
        display: flex;
        flex-direction: column;
    }

    .book-section:not(:first-child){
        margin-top: 2em;
    }

    .book-section h4 {
        margin-bottom: .8em;
    }

    .book-section ol {
        padding: 0;
        list-style-type: none;    
    }

        .book-section-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

            .book-section-entry {
                margin-bottom: 1.4em;
                display: flex;
                flex-basis: 25%;
            }

            .book-section-entry-no-image {
                margin-bottom: 1em;
                flex-basis: 100%;
            }

                .book-section-entry img {
                    margin-right: .6em;
                    width: 4.2em;
                }

                .book-section-entry-no-image .book-info {
                    display: flex;
                    flex-direction: column;
                }

                .book-info {}

                .book-info-details {
                    margin-top: auto;
                    font-size: .75em;
                    font-family: 'Sofia Sans';
                }