.footer-page {
    padding-bottom: 0.4em;
    background-image: url('../../images/bg6_16_9.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

    .footer-content {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
    }
        
        .footer-content a {
            padding: .2em 0.4em 0.1em 0.4em;
            margin-bottom: 0.4em;
            width: fit-content;
            background-color: #c2bab0;
            color: #fff;
            font-style: italic;
        }

        .footer-content a:hover {
            color: #e4eefb;
        }
        
        a.footer-content-site-name {
            padding: .2em 0.4em 0.1em 0.4em;
            margin-top: auto;
            margin-bottom: 0;
            align-self: center;
        }

        .footer-more {
            display: flex;
            flex-direction: column;
        }

            .footer-more h3 {
                width: fit-content;
                display: inline-block;
                background-color: #51403e;
                color: white;
                font-size: var(--h2-size);
            }