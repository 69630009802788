.videos-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    overflow-x: hidden;
}

    .video-header h2 {}

    .videos-content {
        width: 80%;
    }

    .video:not(:first-child) {
        margin-top: 15px;
    }