.personal-gallery-page {
    height: auto;
    min-height: 100vh;
    overflow-x: hidden;
}

.personal-gallery {
    padding: 2.4em 1em 2em 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    font-size: inherit;
}

.personal-gallery-header h2 {}


.personal-masonry-gallery-container {
    width: fit-content;
}