.long-gallery {
  height: auto;
  min-height: 100vh; 
  background-image: url('../../images/bg7.png');
  background-size: contain;
  background-position: top center;
  background-repeat: repeat-y;
}

  .workshops-gallery{
    padding: 2.4em 0 4em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: inherit;
  }

    .gallery-header {
      align-self: center;
      font-size: 1.2em;
    }

    #gallery .gallery-header > h3 {
      padding: .4em 1em;
      font-size: 4em;
      text-align: center;
      background-color: #b1b1c0;
    }


  .workshops-gallery h3 {
    width: fit-content;
  }
  
  .workshop-masonry-gallery-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .masonry-gallery-grid {
  }

  .workshop-masonry-gallery-container h4 {
    margin: 1.2em 0 .6em 0;
  }

  .gallery-h4-workshops {
    background-color: #367e9f;
  }

  .gallery-h4-groups {
    background-color: #8095a0;
  }

  .gallery-h4-garden {
    background-color: #9db6ae;
  }

#gallery > div > div.workshop-masonry-gallery-container img {
  visibility: visible;
}