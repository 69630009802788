.long-about {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100vh;
  background-image: url('../../images/bg5_1_1.jpg');
  background-image: url('../../images/bg_10_16_9.jpg');
  background-size: contain;
  background-position: top;
  background-repeat: repeat-y;
}

  .about-page {
    padding: var(--page-padding);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

    /***BEGIN HEADER***/
    .about-header {
      width: 65%;
    }
    
      .about-header h3 {
        display: inline-block;
        background-color: #de3852;
        background-color: #d4465e;
        font-size: var(--h3-size);
      }
    /***END HEADER***/

    /***ABOUT CONTENT***/
    .about-content {
      width: 65vw;
      display: inline-block;
      overflow: hidden;
      text-align: left;
      font-size: inherit;
      font-family: inherit;
    }

    .about-content-text {
      padding: 2.5em;
      background-color: var(--light-gray);
      color: gray;
    }

    .about-content-text-section > h5 {
      color: darkgray;
      color: #8d8d8d;
    }

      .profile-image {
        margin-right: 1em;
        width: 12em;
        height: 12em;
        float: left;
      }

      .holistic-image {
        margin-left: 1em;
        width: 40%;
        float: right;
      }

      .education-image {
        margin-right: 1em;
        width: 12em;
        float: left;
      }

      .about-content-text b {
        color: var(--yellow-sea);
      }

      .about-thumbnail {
        margin-right: 1em;
        height: 8em;
        display: block;
        float: left;
      }
    
    /***END ABOUT CONTENT***/