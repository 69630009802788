.long-workshops {
  height: auto;
  min-height: 100vh; 
}

  .workshop-layout {
    padding: var(--page-padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background-image: url('../../images/bg6.png');
      background-size: 100% auto; /* Scale to fit the width (x-axis) */
      background-repeat: repeat-y; /* Repeat vertically */
      background-position: top center; /* Align the image to the top and center horizontally */
  }

    /***BEGIN HEADER***/
    .workshops-header {
      width: 65%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

      .workshops-header h3 {
        display: inline-block;
        /*background-color: #004277;
        background-color: #1a4f82;
        background-color: #7ea6cd;*/
        background-image: url('../../images/strip35.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

    /***END HEADER***/

    /***BEGIN DESCRIPTION***/
    .workshops-description {
      display: inline-block;
      width: 60vw;
      overflow: hidden;
      text-align: left;
      font-size: inherit;
      font-family: inherit;
     
    }

      .workshops-text {
        padding: 0;
        color: white;
        background-image: url('../../images/bg9.png');
      background-size: 100% auto; /* Scale to fit the width (x-axis) */
      background-repeat: repeat-y; /* Repeat vertically */
      background-position: top center; /* Align the image to the top and center horizontally */
      }

        .workshop-text-section {
          padding: 2em 3.5em;
          margin-bottom: 0;
        }

        .workshop-text-section p {
          
        }

        
        .workshop-text-section:first-child {
          padding-top: 3.5em;
        } 

        .workshop-text-section:last-child {
          padding-bottom: 3.5em;
        }

        .workshop-text-section > h5 {
          margin-bottom: .4em;
          color: white;
        }

    .picture-description {
      margin-left: auto;
      float: right;
    }

    .workshops-intro-image {
      margin-bottom: .4em;
      width: 100%;
    }

    .workshops-garden-group-image {
      width: 35%;
      margin-right: .8em;
      float: left;
    }

    .workshops-art-group-image {
      width: 40%;
      margin-right: .8em;
      float: left;
    }

    .workshops-image {
      margin-right: .8em;
      width: 25%;
      float: left;
    }

    .awakening-intuition-image {
      cursor: pointer;
    }

    .awakening-intuition-image:hover {
      filter: brightness(90%);
    }

    /***END DESCRIPTION***/

    /***BEGIN TAGLINE***/
    .workshops-tagline {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      gap: .4em;
    }

      .workshops-tagline p {
        /*background-color: var(--tacao);
        background-color: #ed8f8f;
        background-color: #004277;
        background-color: #749bc4;
        background-color: #7ea6cd;*/
        color: var(--light-gray);
        background-image: url('../../images/strip15.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    /***END TAGLINE***/