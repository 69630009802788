html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

:root {
  --page-padding: 2.4em 1em 2em 1em;
  --default-font-size: 16px;
  --h1-padding: .2em .4em .08em;
  --h1-size: 72px;
  --h2-size: 40px;
  --h3-padding: .4em .8em;
  --h3-size: 24px;
  --h4-size: 20px;
  --h6-size: 18px;
  --link-size: 16px;
  --bs-modal-bg: #fff;
  --bs-modal-border-radius: 10px;
  --default-dark-color: #333;
  --default-medium-dark-color: #666;
  --default-light-color: #fff;
  --default-background-color: rgba(255,255,255,.9);
  --light-background-hover-color: #ccc;
  --light-background-active-color: #999;
  --lapis-lazuli: #366697; /*BLUES*/ 
  --blue-gray: #759CC5;
  --ship-covel: #6F9ABF;
  --silver-lake-blue: #6D8BB1;
  --powder-blue: #A6B7D3;
  --yinmn-blue: #3A5A83;
  --midnight: #011640; /*DARK BLUES*/
  --green-vogue: #023059;
  --delft-blue: #37436E;
  --light-gray: #EBEDF2; /*WHITES*/
  --magenta-haze: #A65583; /*REDS*/
  --valencia: #d24c46;
  --salmon: #fe7b6e;
  --wewak: #F299B1;
  --melanie: #E5C8D9;
  --falcon: #81556B;
  --contessa: #c66d65; /*ORANGES*/
  --tacao: #eaac7f; /*YELLOWS*/
  --yellow-sea: #fba905;
  --anzac: #e1c34f;
  --matterhorn: #51403e; /*BROWNS*/
  --sage: #97A27C; /*GREENS*/
}

.default-font-size {
  font-size: var(--default-font-size);
}

.h2-size {
  font-size: var(--h2-size);
}

.link-size {
  font-size: var(--link-size);
}

@font-face {
  font-family: 'Bebas Neue';
  src: local('Bebas Neue'), url('../fonts/Bebas_Neue/BebasNeue-Regular.ttf'), format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Sofia Sans';
  src: url('../fonts/Sofia_Sans/SofiaSans-VariableFont_wght.ttf'), format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Sofia Sans Semi Condensed';
  src: url('../fonts/Sofia_Sans_Semi_Condensed/SofiaSansSemiCondensed-VariableFont_wght.ttf'), format('truetype');
  font-weight: 1 999;
}

@font-face {
  font-family: 'Sofia Sans Condensed';
  src: url('../fonts/Sofia_Sans_Condensed/SofiaSansCondensed-VariableFont_wght.ttf'), format('truetype');
  font-weight: 1 999;
}

.unset-all {
  all: unset;
}

/*TAGS*/
h1 {
  padding: var(--h1-padding);
  font-size: var(--h1-size);
  font-family: 'Bebas Neue';
}

h2 {
  padding: .4em .8em;
  margin: 0;
  font-family: 'Bebas Neue';
}

h3 {
  padding: var(--h3-padding);
  margin: 0;
  font-family: 'Bebas Neue';
}

h4 {
  font-size: var(--h4-size);
}

h6 {
  font-size: var(--h6-size);
}

ul {
  list-style-type: none;
}

a {
  font-family: 'Bebas Neue';
}

/*PAGE*/
.portfolio-pages {
  height: 100vh;
  /*scroll-snap-type: y proximity;*/
  overflow-y: auto;
}

.portfolio-page {
  height: 100vh;
  /*scroll-snap-align: start;*/
  background-color: var(--default-background-color);
  overflow-x: hidden;
}

.page {
    padding: var(--page-padding);
    margin: 0;
    height: 100vh;
    max-width: 100vw;
    /*scroll-snap-align: start;*/
    font-family: 'Bebas Neue';
}

.long-page {
  height: auto;
  min-height: 100vh;
  overflow-x: hidden;
  color: var(--default-light-color);
  font-family: 'Bebas Neue';
}

/*PAGE HEADER*/
.page-header {
  margin-bottom: 1.8em;
  font-size: inherit;
  font-family: inherit;
}

/*TEXT SECTION*/
.text-section {
  margin-bottom: 1.6em;
}

.text-section-title {
  display: block;
  font-size: 2em;
}

/*DESCRIPTION TEXT*/
.description-text p{
  font-family: 'Sofia Sans',sans-serif;
}

/*LINKS*/
.default-link {
  text-decoration: none;
  cursor: pointer;
}

.default-link:hover {
  filter: brightness(120%);
  transition: filter 0.3s ease;
}

.default-link:active {
  filter: brightness(90%);
}


/*THUMBNAIL IMAGE*/
.thumbnail-image {
  margin-right: 1em;
  height: 8em;
  display: block;
  float: left;
}

/*TAG LINE*/
.tagline {
  font-family: 'Bebas Neue';
  font-style: italic;
  font-size: 16px;
}

.tagline p {
  padding: .2em .8em .1em 1em;
  margin: 0;
  font-size: 16px;
}