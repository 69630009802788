/*Small Tablets: 500px - 800px*/
@media (min-width: 550px) {
  :root {
    --page-padding: 2.4em 1em 2em 1em;
    --default-font-size: 20px;
    --h1-padding: .2em .7em .2em .4em;
    --h1-size: 2.8em;
    --h2-size: 2.4em;
    --h3-padding: .4em .8em;
    --h3-size: 2.3em;
    --h4-size: 2em;
    --h4-padding: .6em .8em;
    --link-size: 1.1em;
    --tagline-size: 1em;
    --essay-link-size: 1.4em
  }  

  .page {
    padding: var(--page-padding);
    font-size: var(--default-font-size);
  }

  .page h1 {
    padding: var(--h1-padding);
    font-size: var(--h1-size);
  }

  .page h2 {
    font-size: var(--h2-size);
  }

  .page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h4 {
    padding: var(--h4-padding);
    font-size: var(--h4-size);
  }

  .navigation-link {
    font-size: var(--h2-size);
  }

  .link-size {
    font-size: var(--link-size);
  }
  
  .tagline {
    font-size: var(--tagline-size);
  }

  .tagline p {
    font-size: var(--tagline-size);
  }

  /*home*/
  .site-title-description {
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }

  /*workshops*/
  .workshops-tagline {
    display: visible;
  }

  .workshops-description {
    width: 100%;
  }

  .workshops-header {
    width: 100%;
  }

  /*gallery*/

  /*about*/
  .about-header {
    width: 100%;
  }

  .about-content {
    width: 100%;
  }

  /*footer*/
}

/*Large Tablets: 800px - 1024*/
@media (min-width: 800px) {
  :root {
    --page-padding: 2.4em 1em 2em 1em;
    --default-font-size: 20px;
    --h1-padding: .2em .7em .2em .4em;
    --h1-size: 2.8em;
    --h2-size: 2.4em;
    --h3-padding: .4em .8em;
    --h3-size: 2.5em;
    --h4-size: 2.2em;
    --h4-padding: .4em .8em;
    --link-size: 1.2em;
    --tagline-size: 1em;
    --essay-link-size: 1.4em;
  }  

  .page {
    padding: var(--page-padding);
    font-size: var(--default-font-size);
  }

  .page h1 {
    padding: var(--h1-padding);
    font-size: var(--h1-size);
  }

  .page h2 {
    font-size: var(--h2-size);
  }

  .page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h4 {
    padding: var(--h4-padding);
    font-size: var(--h4-size);
  }

  .navigation-link {
    font-size: var(--h2-size);
  }

  .link-size {
    font-size: var(--link-size);
  }
  
  .tagline {
    font-size: var(--tagline-size);
  }

  .tagline p {
    font-size: var(--tagline-size);
  }

  .site-title-description {
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }

  /*workshops*/
  .workshops-tagline {
    display: visible;
  }

  .workshops-description {
    width: 85%;
  }

  .workshops-header {
    width: 85%;
  }

  .about-header {
    width: 85%;
  }

  .about-content {
    width: 85%;
  }
}