.modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }

    .modal-image {
      cursor: pointer;
    }
  
    .modal-content {
      max-width: 80%;
      max-height: 80%;
      padding: 2em;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #fff;
      cursor: default;
    }

      .modal-image-header {
        display: flex;
        justify-content: space-between;
      }

        .modal-alt {
          font-size: 3em;
        }
    
        .modal-close-button {
          padding: .2em .6em;
          align-self: center;
          border: none;
          font-size: 1.5em;
          background-color: var(--light-gray);
          color: var(--silver-lake-blue);
          cursor: pointer;
        }

        .modal-close-button:hover {
          filter: brightness(90%);
        }
      
      .modal-overlay-image {
        margin: 1em 0;
        width: 100%;
      }

    .modal-content-description {
      font-family: 'Sofia Sans', sans-serif;
      color: gray;
    }