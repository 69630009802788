/*1025px and up */
@media (min-width: 1025px) {
  :root {
    --page-padding: 2.2em .8em 1.8em .8em;
    --default-font-size: 20px;
    --h1-padding: .2em .7em .2em .4em;
    --h1-size: 3.8em;
    --h2-size: 2.8em;
    --h3-padding: .4em .8em;
    --h3-size: 2.8em;
    --h4-size: 2.6em;
    --h4-padding: .6em .8em;
    --link-size: 1.4em;
    --tagline-size: 1.1em;
    --essay-link-size: 1.4em;
  }

  .page {
    padding: var(--page-padding);
    font-size: var(--default-font-size);
  }

  .page h1 {
    padding: var(--h1-padding);
    font-size: var(--h1-size);
  }

  .page h2 {
    font-size: var(--h2-size);
  }

  .page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h4 {
    padding: var(--h4-padding);
    font-size: var(--h4-size);
  }

  .navigation-link {
    font-size: var(--h2-size);
  }

  .link-size {
    font-size: var(--link-size);
  }
  
  .tagline {
    font-size: var(--tagline-size);
  }

  .tagline p {
    font-size: var(--tagline-size);
  }

  .site-title-description {
    flex-direction: row;
    align-items: flex-end;
    gap: unset;
  }

  /*workshops*/
  .workshops-tagline {
    display: visible;
  }

  .workshops-description {
    width: 70%;
  }

  .workshops-header {
    width: 70%;
  }

  .about-header {
    width: 70%;
  }

  .about-content {
    width: 70%;
  }
}

/*Extra large*/
@media (min-width: 1500px){
  :root {
    --page-padding: 2.4em 1em 2em 1em;
    --default-font-size: 24px;
    --h1-padding: .2em .7em .2em .4em;
    --h1-size: 4em;
    --h2-size: 2.8em;
    --h3-padding: .4em .8em;
    --h3-size: 2.8em;
    --h4-size: 2.6em;
    --h4-padding: .4em .8em;
    --link-size: 1.6em;
    --tagline-size: 1.1em;
    --essay-link-size: 1.6em;
  }

  .page {
    padding: var(--page-padding);
    font-size: var(--default-font-size);
  }

  .page h1 {
    padding: var(--h1-padding);
    font-size: var(--h1-size);
  }

  .page h2 {
    font-size: var(--h2-size);
  }

  .page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .page h4 {
    margin: 0;
    font-size: var(--h4-size);
  }

  .long-page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h4 {
    padding: var(--h4-padding);
    font-size: var(--h4-size);
  }

  .navigation-link {
    font-size: var(--h2-size);
  }

  .link-size {
    font-size: var(--link-size);
  }
  
  .tagline {
    font-size: var(--tagline-size);
  }

  .tagline p {
    font-size: var(--tagline-size);
  }

  .site-title-description {
    flex-direction: row;
    align-items: flex-end;
    gap: unset;
  }

  /*workshops*/
  .workshops-tagline {
    display: visible;
  }

  .workshops-description {
    width: 80%;
  }

  .workshops-header {
    width: 80%;
  }

  .workshop-text-section:first-child {
    padding-top: 4.5em;
  }

  .workshop-text-section {
    padding: 2em 4em; 
  }

  .about-header {
    width: 80%;
  }

  .about-content {
    width: 80%;
  }
}