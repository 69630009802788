@media (max-width: 549px){
  :root {
    --page-padding: 1.4em .4em 1.4em .4em;
    --default-font-size: 16px;
    --h1-padding: .2em .8em .08em .4em;
    --h1-size: 3.4em;
    --h2-size: 2.6em;
    --h3-padding: .4em .8em;
    --h3-size: 2.3em;
    --h4-size: 2em;
    --h4-padding: .4em .8em;
    --link-size: 1.1em;
    --tagline-size: 1em;
    --essay-link-size: 1.4em
  }  

  .page {
    padding: var(--page-padding);
    font-size: var(--default-font-size);
  }

  .page h1 {
    padding: var(--h1-padding);
    font-size: var(--h1-size);
  }

  .page h2 {
    font-size: var(--h2-size);
  }

  .page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h3 {
    padding: var(--h3-padding);
    margin: 0;
    font-size: var(--h3-size);
  }

  .long-page h4 {
    padding: var(--h4-padding);
    font-size: var(--h4-size);
  }

  .navigation-link {
    font-size: var(--h2-size);
  }

  .link-size {
    font-size: var(--link-size);
  }
  
  .tagline {
    font-size: var(--tagline-size);
  }

  .tagline p {
    font-size: var(--tagline-size);
  }

  /*home page*/
  .home-page {
    background-image: url('../../images/bg_9_16.jpg');
  }

  .header {
    justify-content: flex-start;
  }

  .navigation-links {
    align-items: flex-start;
  }

  .navigation-link {
    padding: 0.2em 0.8em .04em .6em;
  }

  .workshops-link{
    background-image: url('../../images/strip44.png');
  }

  .gallery-link {
    background-image: url('../../images/strip44.png');
  }

  .essays-link {
    background-image: url('../../images/strip44.png');
  }

  .about-link {
    background-image: url('../../images/strip44.png');
  }

  .site-title-section {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 8px;
  }

  .site-title-description {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  /*workshops page*/
  .long-workshops {
    background-image: url('../../images/IMG_5004_9_16.jpg');
  }

  .workshops-tagline {
    display: none;
  }

  .workshops-header {
    width: 100%;
  }

  .workshops-description {
    width: 100%;
  }

  .workshop-text-section {
    padding-top: 2em;
    margin-bottom: 1em;
  }

  .workshops-garden-group-image {
      margin-right: .8em;
      margin-bottom: 1em;
      width: 100%;
      float: left;
    }

    .workshops-art-group-image {
      margin-right: .8em;
      margin-bottom: 1em;
      width: 100%;
      float: left;
    }

    .workshops-image {
      margin-right: .8em;
      margin-bottom: 1em;
      width: 100%;
      float: left;
    }
  
  /*gallery page*/
  .long-gallery {
    background-image: url('../../images/IMG_4940_9_16.jpg');
  }

  #gallery .gallery-header > h3 {
    padding: .4em .4em .35em .4em;
  }

  /*essays*/
  .essays-page {
    background-image: url('../../images/IMG_5030_9_16.jpg');
  }

  /*about page*/
  .long-about {
    background-image: url('../../images/bg10_9_16.jpg');
  }
  .about-header {
    width: 100%;
  }
  .about-content {
    width: 100%;
  }
  /*footer page*/
}