.home-page{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  background-image: url('../../images/bg_16_9.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: inherit;
    font-family: inherit;
  }

    .navigation-links {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: inherit;
      font-family: inherit;
    }

      .navigation-link:first-child {
        margin-top: 0;
      }
    
      .navigation-link {
        padding: 0.2em 0.8em .04em 1em;
        margin: .15em 0 .15em .08em;
        display: block;
        background-color: none;/*var(--silver-lake-blue);*/
        color: var(--light-gray);
        font-size: inherit;
        font-family: inherit;
        font-style: none;
        font-weight: bold;
        text-decoration: none;
      } 

      .navigation-link span {
        opacity: .9;
      }
      
      /*26, 28, 35*/

      .workshops-link{
        background-image: url('../../images/strip42.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .gallery-link {
        background-image: url('../../images/strip42.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .essays-link {
      background-image: url('../../images/strip42.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .about-link {
        background-image: url('../../images/strip42.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      a.navigation-link:hover {
        filter: brightness(120%);
      }

  .site-title-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    font-size: inherit;
    color: var(--light-gray);
  }

    .site-title-section h1 {
      margin: 0;
      background-color: none;/*var(--light-gray);*/
      color: white;/*var(--powder-blue);*/
    }

    /*15, 26*/
    .site-title-name {
      background-image: url('../../images/strip45.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .site-title-description {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: inherit;
        
    }

      .site-title-description p {
        padding: .2em .8em .1em 1em;
        background-color: none;/*var(--powder-blue);*/
        color: var(--light-gray);
        background-image: url('../../images/strip41.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }