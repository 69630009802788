.more-page {
    background-color: white;
    color: var(--silver-lake-blue);
}

.more-page h4 {
    font-size: 2em;
}

.more-page h6 {
    padding: .4em;
    margin: 0;
    width: fit-content;
    font-size: 1.4em;
    font-family: 'Sofia Sans Semi Condensed';
    color: var(--silver-lake-blue);
}

    .more-header {
    }

    .more-header h1 {
        padding: .2em .4em .2em 0;
        margin-bottom: .4em;
        width: fit-content;
        color: var(--silver-lake-blue);
    }

    #more-site {
        padding-left: 0;
    }

    .more-page-header {
        padding-top: 1em;
        padding-bottom: 1em;
        margin: 2em 0;
        border-bottom: .4em solid var(--silver-lake-blue);
        border-top: .4em solid var(--silver-lake-blue);
    }

    .more-page-header h2 {
        padding: 0;
        margin-bottom: .2em;
    }

    .more-page-header h2 {
        padding: 0;
        margin-bottom: .2em;
    }

    .more-nav-items {
        display: flex;
        flex-wrap: wrap;
    }

    .more-nav-items a {
        padding: 0.4em 4em 0.3em 4em;
        margin-right: .6em;
        margin-bottom: .6em;
        font-size: 1.2em;
        background-color: var(--light-gray);
        color: var(--silver-lake-blue);
        text-decoration: none;
    }

    .more-page-body {

    }

    .more-page-body a {
        color: var(--silver-lake-blue);
        text-decoration: none;
    }